import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Mafia on Alexa" link="https://github.com/RasPat1/alexa-game" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Alexa runs the game and you pass and receive secrets via sms.
    </ProjectCard>
    <ProjectCard title="Musical Chairs" link="https://github.com/RasPat1/spotify-slack-integration-webhook" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  Convert a slack channel into a community "Now Playing" feed.
    </ProjectCard>
    <ProjectCard title="Withdrawalolz" link="https://github.com/RasPat1/withdrawlolz" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  A dump of dozens of one-off experiments and unfinished projects.
    </ProjectCard>
    <ProjectCard title="Dotfiles" link="https://github.com/RasPat1/dotfiles" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  A consistent development environment is the key to flow. --Oscar Wilde
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      