import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`I'm a startup advisor, consulting CTO, systems architect, web developer, data analyst, pen tester, writer, and speaker.`}</p>
    </blockquote>
    <p>{`Previously, CTO at Bravely and Director of Growth at Shoptiques.com.`}</p>
    <p>{`Currently, working on Big Data at Google.`}</p>
    <p>{`Contact me below via linkedin for engagements.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      